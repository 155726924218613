// Import the main component
import { Height } from "@mui/icons-material";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

interface Props {
    urlFile?: string;
}

/* The code defines a functional component called `PDFViewer` that takes a prop `urlFile` of type
string. Inside the component, it returns a JSX element that renders a PDF viewer. */
const PDFViewer = ({ urlFile }: Props) => {
    return (
        <div style={{ height: '100vh' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <div style={{ height: '100vh' }}>
                    <Viewer fileUrl={urlFile ? urlFile : "https://example.com/document.pdf"} />
                </div>
            </Worker >
        </div>
    );
};

export default PDFViewer;