import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route, Router, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Chat from "./pages/chat/Chat";
import Login from "./pages/login/login";
import ResponsiveAppBar from "./components/menu/TopBarTop";
import PDFViewer from "./pages/pdf/pdfVisor";
import { decryptAES, secretKeyPublic } from "./firebase";

initializeIcons();

/**
 * The function checks if a user is logged in and returns a Chat component if they are, otherwise it
 * returns a Login component.
 * @returns either a component called `<Chat>` with a prop `nameUser` set to `data.displayName`, or a
 * component called `<Login>`.
 */
function CheckLogin() {
    try {
        const item = localStorage.getItem("user-super");
        const data = JSON.parse(decryptAES(item, secretKeyPublic));
        // console.log(data);
        if (item) {
            return <Chat nameUser={data.displayName} />;
        } else {
            return <Login />;
        }
    } catch (error) {
        return <Login />;
    }
}


/**
 * The App component renders a div with the class "App watermark" and includes a Routes component with
 * a single Route that renders the CheckLogin component when the path is "/".
 * @returns The App component is returning a JSX element.
 */
const App = () => {
    return (
        <div className="App watermark">
            {/* <Sidenav /> */}
            {/* <ResponsiveAppBar /> */}

            <Routes>
                <Route path="/" element={CheckLogin()} />
            </Routes>
        </div>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
