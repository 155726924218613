import { getAuth, signInWithRedirect, OAuthProvider, onAuthStateChanged, initializeAuth, getRedirectResult } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import { AES, enc } from "crypto-js";
import bcrypt from "bcryptjs";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBDsRMgRI9HoW_ot9wCsFTzfSD3zru97Q0",
    authDomain: "superapisgpt.firebaseapp.com",
    projectId: "superapisgpt",
    storageBucket: "superapisgpt.appspot.com",
    messagingSenderId: "807128540689",
    appId: "1:807128540689:web:d4d61a3cbc6e3c978c73b0",
    measurementId: "G-FJFRNZXGS9"
};

export const secretKeyPublic = "8b5DhR7J6mP3xW1sC9vA2kN4";

function encryptAES(text: string, secretKey: string): string {
    const encrypted = AES.encrypt(text, secretKey).toString();

    return encrypted;
}

// Descifrar utilizando AES-256
export function decryptAES(encrypted: string, secretKey: string): string {
    const decrypted = AES.decrypt(encrypted, secretKey).toString(enc.Utf8);

    return decrypted;
}

function generateJWT(payload: object, expiresInMinutes: number) {
    // Define the secret key for signing the JWT

    // Calculate the expiration time in seconds
    const expirationTime = Math.floor(Date.now() / 1000) + expiresInMinutes * 60;
    // Add the expiration time to the payload
    const payloadWithExpiration = {
        ...payload,
        exp: expirationTime
    };
    // Generate the JWT
    const tokenData = JSON.stringify(payloadWithExpiration);
    // Generate the JWT
    const encryptedText = encryptAES(tokenData, secretKeyPublic);

    return encryptedText;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Create an instance of the Microsoft authentication provider
const provider = new OAuthProvider("microsoft.com");
provider.setCustomParameters({
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    tenant: "4764267f-7904-47a7-bb52-87b5f1579057"
    // prompt: "consent"
});
const auth = getAuth();
// Function to handle sign-in with Microsoft
export function signInWithMicrosoft() {
    signInWithRedirect(auth, provider);
}

let loadinLogin: boolean = false;

export const SignInButton: React.FC = () => {
    useEffect(() => {
        const handleRedirectResult = async () => {
            try {
                loadinLogin = true;
                const result = await getRedirectResult(auth);
                if (result) {
                    // El usuario ha iniciado sesión exitosamente
                    console.log("Token de acceso:", result.user);
                    const jwt = generateJWT(result.user, 24 * 60);
                    localStorage.setItem("user-super", jwt);

                    const credential = OAuthProvider.credentialFromResult(result);
                    window.location.replace("/?");
                    loadinLogin = false;
                    //console.log("Token de acceso:", credential);
                }
            } catch (error) {
                loadinLogin = false;
                // Manejar errores de inicio de sesión
                console.log("Error en el inicio de sesión:", error);
            }
        };

        handleRedirectResult();
    }, []);

    return loadinLogin ? (
        <>WAIT</>
    ) : (
        <button id="sign-in-button" className="microsoft-signin-button" onClick={signInWithMicrosoft}>
            <div className="button-content">
                {MicrosoftIcon()}
                <span>Sign In with Microsoft</span>
            </div>
        </button>
    );
};

// React component with the sign-in button

const MicrosoftIcon = () => {
    return (
        <svg className={`microsoft-icon`} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path className="path" d="M11.4737 2H2V11.4737H11.4737V2Z" fill="#F25022" />
            <path className="path" d="M11.4737 12.5263H2V22H11.4737V12.5263Z" fill="#00A4EF" />
            <path className="path" d="M22 2H12.5263V11.4737H22V2Z" fill="#7FBA00" />
            <path className="path" d="M22 12.5263H12.5263V22H22V12.5263Z" fill="#FFB900" />
        </svg>
    );
};
