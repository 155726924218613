import HomeIcon from "@mui/icons-material/Home";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
const navData = [
    {
        id: 0,
        icon: <HomeIcon />,
        text: "Home",
        link: "/"
    },
    {
        id: 1,
        icon: <TravelExploreIcon />,
        text: "Procedimientos",
        link: "procedimientos"
    },
    {
        id: 2,
        icon: <BarChartIcon />,
        text: "Soporte",
        link: "support"
    },
    {
        id: 3,
        icon: <SettingsIcon />,
        text: "Comunicados",
        link: "comunicate"
    },
    {
        id: 4,
        icon: <SettingsIcon />,
        text: "Preguntas Frecuentes",
        link: "faq"
    },
    {
        id: 5,
        icon: <SettingsIcon />,
        text: "Contacto",
        link: "contact"
    }
];
const UrlDocument = "https://stdxb67wgl4xdri.blob.core.windows.net";
const nameApp = "Super Apis IO SPA";

export { navData, nameApp, UrlDocument };
