import React from "react";
import "./who.css";
import { Grid, Typography } from "@mui/material";

interface Props {
    onExampleClicked: (value: string) => void;
}

export const BoxWho = ({ onExampleClicked }: Props): JSX.Element => {
    return (
        <div>
            <Grid container spacing={2} style={{ margin: "0 auto", width: "100%", textAlign: "center", padding: "20px" }} className="who-box">
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Chat</Typography>
                    <Typography>Aprenda a usar modelos de lenguaje basados ​​en chat</Typography>
                </Grid>
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Organizacion</Typography>
                    <Typography>Busca Información de tu Empresa</Typography>
                </Grid>
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Documentos</Typography>
                    <Typography>Lectura de Documentos Online</Typography>
                </Grid>
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Asistente 24x7</Typography>
                    <Typography>100% disponible para colaboradores</Typography>
                </Grid>
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Referencias</Typography>
                    <Typography>Busca en grandes volumen de datos información empresa</Typography>
                </Grid>
                <Grid xs={6} md={6} className="spaccing">
                    <Typography className="who-box-text-wrapper">Conocimiento</Typography>
                    <Typography>Aprendizaje basado de tu empresa</Typography>
                </Grid>
            </Grid>
        </div>
    );
};
