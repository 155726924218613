import { Fragment, useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import IconButtonMui from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { Button, Modal, Snackbar } from "@mui/material";
import { UrlDocument } from "../../navData";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onUrlViewFile: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onUrlViewFile
}: Props) => {

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);



    function stripHtmlTags(html: string): string {
        return html.replace(/<[^>]*>/g, '');
    }


    const action = (
        <Fragment>
            <Button color="primary" size="small" onClick={handleClose}>
                CERRAR
            </Button>
            <IconButtonMui
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButtonMui>
        </Fragment>
    );


    function copiarTexto(texto: string): void {




        const elementoTemporal = document.createElement("textarea");
        elementoTemporal.value = stripHtmlTags(texto);

        // Asegurarse de que el elemento sea visible y no esté fuera del rango de desplazamiento actual
        elementoTemporal.style.position = "fixed";
        elementoTemporal.style.left = "-9999px";
        elementoTemporal.style.top = "-9999px";

        document.body.appendChild(elementoTemporal);
        elementoTemporal.select();
        elementoTemporal.setSelectionRange(0, texto.length); // Para dispositivos móviles

        // Copiar el texto al portapapeles
        let exito = false;
        try {
            exito = document.execCommand("copy");
            handleClick()
        } catch (err) {
            console.error("Error al copiar el texto: ", err);
        }

        document.body.removeChild(elementoTemporal);

        if (exito) {
            setOpen(true);
        } else {
            // console.error("No se pudo copiar el texto al portapapeles.");
        }
    }


    return (
        <>
            <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon />

                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Copy" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => copiarTexto(sanitizedAnswerHtml)}
                            disabled={!answer.thoughts}
                        />


                        {/* <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div> */}
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </Stack.Item>

                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Cita:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <a
                                        key={i}
                                        className={styles.citation}
                                        title={x}
                                        onClick={() => {
                                            // onCitationClicked(path)
                                            //https://stdxb67wgl4xdri.blob.core.windows.net/content/Descriptivo de roles y responsabilidades-0.pdf
                                            onUrlViewFile(UrlDocument + path);
                                        }}
                                    >
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <span className={styles.followupQuestionLearnMore}>Preguntas de seguimiento:</span>
                            {parsedAnswer.followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
            </Stack>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Texto Copiado a portapapeles"
                action={action}
            />


        </>
    );
};
